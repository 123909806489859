import CopyToClipboard from "@/components/ui/CopyToClipboard";
import LoadingDots from "@/components/ui/Loading/LoadingDots";
import Modal from "@/components/ui/Modal";
import { rainbowKitConfig } from "@/lib/rainbowkit";
import { getCachedPriceData } from "@/server/actions/currency";
import { logoutUser } from "@/server/actions/user/auth/logout";
import { shortenAddress } from "@/utils/common";
import { getUsdRate } from "@/utils/currency";
import { getBlockExplorer } from "@/utils/network";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { getChainId } from "@wagmi/core";
import { ArrowUpRight } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { useAccount, useBalance, useConfig, useDisconnect } from "wagmi";
import { create } from "zustand";
import IconWallet from "../../../ui/Icons/IconWallet";

interface WalletModalState {
  open: boolean;
  setOpen: (walletModal: boolean) => void;
}

export const useWalletModalStore = create<WalletModalState>((set) => ({
  open: false,
  setOpen: (open) => set({ open }),
}));

type Props = {
  formattedAddress?: string;
  balance?: string;
  avatar?: string;
  openChainModal: () => void;
  chainIconUrl: string | undefined;
};

export default function RainbowKitWalletModal({
  formattedAddress,
  balance,
  avatar,
  openChainModal,
  chainIconUrl,
}: Props) {
  const { open, setOpen } = useWalletModalStore();
  const router = useRouter();
  const [loggingOut, setLoggingOut] = useState(false);
  const { disconnect } = useDisconnect();
  const { address, connector } = useAccount();

  // 1. Get native balance
  const { data: nativeBalanceData, isLoading: nativeBalanceLoading } =
    useBalance({
      address,
    });

  // 2. Get USD rate data
  const [priceConversionsToUsd, setPriceConversionsToUsd] =
    useState<any | null>(null);
  const [nativeTokenUSDValue, setNativeTokenUSDValue] =
    useState<number | null>(null);

  // Function to calculate and set the native token USD value
  async function updateNativeTokenUSDValue() {
    if (!nativeBalanceData?.symbol || !priceConversionsToUsd) return;
    const usdRate = await getUsdRate(
      nativeBalanceData.symbol,
      priceConversionsToUsd
    );
    const amountAsNumber = parseFloat(nativeBalanceData.formatted ?? "0");
    const valueInUSD = amountAsNumber * usdRate;
    setNativeTokenUSDValue(valueInUSD);
  }

  useEffect(() => {
    const getCachedPrices = async () => {
      const pricesConversions = await getCachedPriceData();
      setPriceConversionsToUsd(pricesConversions);
    };
    getCachedPrices();
  }, []);

  useEffect(() => {
    // Update USD value whenever we get new price data or new balance data
    if (nativeBalanceData && priceConversionsToUsd) {
      updateNativeTokenUSDValue();
    }
  }, [nativeBalanceData, priceConversionsToUsd]);

  const path = usePathname();

  const handleLogout = async () => {
    try {
      setLoggingOut(true);
      if (path?.includes("/settings")) {
        router.push("/");
      }
      disconnect();
      await logoutUser();
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setLoggingOut(false);
    }
  };

  const [activeTab, setActiveTab] = useState("cryptocurrency");

  const walletImage = () => {
    const noramlizedWalletName = connector?.name.toLowerCase();
    switch (noramlizedWalletName) {
      case "metamask":
        return "/wallets/metamask.png";
      case "walletconnect":
        return "/wallets/wallet-connect.png";
      case "coinbase":
        return "/wallets/coinbase.png";

      default:
        return avatar || "";
    }
  };
  const chainId = getChainId(rainbowKitConfig);
  const { url, explorerName } = getBlockExplorer(
    chainId,
    "address",
    address || ""
  );

  return (
    <>
      {open && (
        <Modal
          open={open}
          setOpen={setOpen}
          width="sm:w-[400px] sm:max-w-[400px] sm:min-w-[400px] w-full md:max-h-[90%]"
        >
          <Image
            src={walletImage()}
            alt="wallet image"
            width={120}
            height={120}
            className="opacity-20 rounded-full object-contain mx-auto "
          />

          <div className="grid justify-center  mb-8 mt-2">
            {balance && (
              <div
                className="py-2 font-bold text-[32px] xs:text3-xl md:text-5xl 
                text-center bg-clip-text text-transparent bg-gradient-to-br from-primary via-[#c158f2] to-[#FE54A5]/60"
              >
                {balance}
              </div>
            )}
            {/* Display native token balance and symbol */}
            {nativeBalanceLoading ? (
              <LoadingDots />
            ) : (
              <div className="text-center">
                {/* {nativeBalanceData?.formatted} {nativeBalanceData?.symbol} */}
                {nativeTokenUSDValue !== null && (
                  <div className="text-xl text-white/80 ">
                    ≈ ${nativeTokenUSDValue.toFixed(2)} USD
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="grid gap-4 mt-2 ">
            {formattedAddress && (
              <button className="transition hover:brightness-125  w-full  rounded-xl bg-dark-default-1 px-3 py-5 flex items-center justify-center gap-x-3">
                <IconWallet className="max-w-[24px] min-w-[24px]" />
                {shortenAddress(formattedAddress, 6)}
                <CopyToClipboard text={formattedAddress} />
              </button>
            )}

            <button
              onClick={openChainModal}
              className="transition hover:brightness-125 rounded-xl bg-dark-default-1 px-3 py-5 flex items-center justify-center gap-x-2"
            >
              <span className="sr-only">Open blockchain options</span>
              <>
                Change Network
                {chainIconUrl && (
                  <span className="flex items-center">
                    <img
                      alt="Chain icon"
                      src={chainIconUrl}
                      className="h-5 w-5 min-w-[20px] object-cover sm:object-contain mr-1 group-hover:scale-105 rounded-full"
                    />
                  </span>
                )}
                <ChevronDownIcon
                  className="h-4 w-4 opacity-80 flex"
                  aria-hidden="true"
                />
              </>
            </button>

            {/* <BtnTabs activeTab={activeTab} setActiveTab={setActiveTab} /> */}
            <div className="grid gap-4 md:grid-cols-7">
              <button
                onClick={handleLogout}
                className="md:col-span-3 w-full transition hover:brightness-125 rounded-xl bg-dark-default-1 px-3 py-5 flex items-center justify-center gap-x-2"
              >
                {loggingOut ? (
                  <LoadingDots />
                ) : (
                  <>
                    Log out
                    <span className="p-[6px] rounded-full bg-dark-pri/20">
                      <FiLogOut />
                    </span>
                  </>
                )}
              </button>
              <Link
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="md:col-span-4 w-full transition hover:brightness-125 rounded-xl bg-dark-default-1 px-3 py-5 flex items-center justify-center gap-x-2"
              >
                <img
                  src={`/block-explorers/${chainId}.png`}
                  className="ml-2 h-[20px] w-[20px] min-w-[20px] cursor-pointer  hover:scale-110"
                  alt={`${explorerName} block explorer logo`}
                />
                {explorerName} <ArrowUpRight className="min-w-5 w-5" />
              </Link>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
