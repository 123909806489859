"use client";
import Modal from "@/components/ui/Modal";
import Terms from "@/components/ui/Terms";
import Image from "next/image";
import { create } from "zustand";
import LoginForm from "./LoginForm";

interface LoginModalState {
  openLoginModal: boolean;
  setOpenLoginModal: (openLoginModal: boolean) => void;
}

export const useLoginModalStore = create<LoginModalState>((set) => ({
  openLoginModal: false,
  setOpenLoginModal: (openLoginModal) => set({ openLoginModal }),
}));

const LoginModal = () => {
  const { openLoginModal, setOpenLoginModal } = useLoginModalStore();

  return (
    <Modal
      open={openLoginModal}
      setOpen={setOpenLoginModal}
      width="w-full sm:w-[400px] sm:max-w-[400px]"
    >
      <>
        <div className="mx-auto p-4 relative flex flex-col items-center justify-center transition">
          <img
            src="/wallets/metamask.png"
            alt="metamask"
            className="transition opacity-10   w-40 h-auto object-contain -rotate-[30deg] absolute -left-10"
          />
          <img
            src="/wallets/coinbase.png"
            alt="metamask"
            className="transition opacity-10   w-32 h-auto object-contain -rotate-[30deg] absolute top-6  -right-20"
          />
          {/* <div className="-z-10 rotate-[60deg] opacity-30 bg-gradient-to-br from-dark-pri to-primary  blur-2xl rounded-full absolute -top-6 -right-12 w-[100px] h-[700px]" /> */}
          <div className="relative ">
            <div
              className="-z-10 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 
            w-[42px] h-[42px] bg-gradient-to-br from-primary to-[#2ff9da] blur-xl rounded-full "
            />
            <Image
              src="/rodeo-logo.png"
              alt="Rodeyo Logo"
              width={58}
              height={42}
              className="w-[32px] h-auto object-contain mb-2"
            />
          </div>
        </div>
        <div className="mb-[24px] justify-center text-center text-3xl font-extrabold md:text-[36px] flex items-center gap-x-2">
          <span className="from-white to-light-gray/60 bg-clip-text bg-gradient-to-b text-transparent leading-[46px]">
            Log in
          </span>
        </div>
        <div className="h-[24px]" />
        <LoginForm />

        
        <div className="sm:pb-[32px]" />
      </>
    </Modal>
  );
};

export default LoginModal;
