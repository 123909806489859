import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { http } from "wagmi";
import { arbitrum, base, mainnet, optimism, polygon } from "wagmi/chains";

// const API_KEY = process.env.ALCHEMY_ETH_MAINNET_KEY!;

const API_KEY = process.env.NEXT_PUBLIC_INFURA_KEY;

export const rainbowKitConfig = getDefaultConfig({
  appName: "Rodeyo",
  projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID!,
  chains: [mainnet, polygon, optimism, arbitrum, base],
  transports: {
    [mainnet.id]: http(`https://mainnet.infura.io/v3/${API_KEY}`),
    [polygon.id]: http(`https://polygon-mainnet.infura.io/v3/${API_KEY}`),
    [optimism.id]: http(`https://optimism-mainnet.infura.io/v3/${API_KEY}`),
    [arbitrum.id]: http(`https://arbitrum-mainnet.infura.io/v3/${API_KEY}`),
    [base.id]: http(`https://base-mainnet.infura.io/v3/${API_KEY}`),
  },
  ssr: true,
});
