"use client";
import DividerWithText from "@/components/ui/Divider/DividerWithText";
import { motion } from "framer-motion";
import { useState } from "react";
import { useMagic } from "../../../../context/MagicProvider";
import PrimaryButton from "../../../ui/Forms/Button/PrimaryBtn";
import { BtnRainbowKit } from "../BtnRainbowKit";
import { handleMagicLogin } from "../handleMagicLogin";
import { useLoginModalStore } from "./ModalWrapper";
import Terms from "@/components/ui/Terms";

export default function LoginForm() {
  const { magic } = useMagic();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [isLoginInProgress, setLoginInProgress] = useState(false);
  const { setOpenLoginModal } = useLoginModalStore();

  return (
    <>
      <div className="px-4">
        <BtnRainbowKit />
      </div>
      <DividerWithText text="or" />
      <form
        className="grid gap-y-[12px] mt-[36x] mb-[12px] px-4 "
        onSubmit={(e) =>
          handleMagicLogin(
            e,
            email,
            setEmail,
            setEmailError,
            setLoginInProgress,
            magic,
            setOpenLoginModal
          )
        }
      >
        <div>
          <input
            disabled={isLoginInProgress}
            type="email"
            name="email"
            id="email"
            onChange={(e) => {
              if (emailError) setEmailError(false);
              setEmail(e.target.value);
            }}
            placeholder="mostly@harmless.com"
            value={email}
            className="w-full flex-1 outline-none rounded-md border-2 border-white/20 bg-white/10 px-3.5 py-3 
          text-white shadow-sm  hover:border-dark-pri focus:border-dark-pri sm:leading-6"
          />
        </div>

        {emailError && <span className="error">Enter a valid email</span>}

        {email && email.length > 0 && (
          <motion.div
            whileInView={{ opacity: 1, y: 1 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="w-full "
          >
            <PrimaryButton
              type="submit"
              loading={isLoginInProgress}
              disabled={isLoginInProgress || !email || email.length === 0}
              style="w-full"
            >
              <span className="py-0.5 md:py-0">Continue with email</span>
            </PrimaryButton>
          </motion.div>
        )}
      </form>
      <Terms clickText="Login / Sign up" />
    </>
  );
}
