import { logoutUser } from "@/server/actions/user/auth/logout";
import { Magic } from "./types";

// * Common MAGIC methods * //

export type LoginMethod = "EMAIL" | "SMS" | "SOCIAL" | "FORM";

export const logout = async (magic: Magic | null) => {
  if (await magic?.user.isLoggedIn()) {
    await logoutUser();
    try {
      await magic?.user.logout();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  }
  window.open("/", "_self");
  window.location.reload();
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
};

export const saveUserInfo = (
  token: string,
  loginMethod: LoginMethod,
  userAddress: string
) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1); // Expiration of one year from now

  const secureFlag = "Secure";

  // Prevent access via client-side scripts
  const httpOnlyFlag = "HttpOnly";
  const sameSiteFlag = "SameSite=Strict";

  if (typeof window !== "undefined" && window.localStorage) {
    localStorage.setItem("token", token);
    localStorage.setItem("isAuthLoading", "false");
    localStorage.setItem("loginMethod", loginMethod);
    localStorage.setItem("user", userAddress);
    document.cookie = `user=${userAddress}; expires=${date.toUTCString()}; path=/; ${secureFlag} ${httpOnlyFlag} ${sameSiteFlag}`;
  }
};

export const getUserWalletAddress = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    return localStorage.getItem("user");
  }
};

export const shortenAddress = (address: string, slice: number = 4) => {
  return `${address?.slice(0, slice)}...${address?.slice(-slice)}`;
};

export const formatTime = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return { hours, minutes, seconds };
};

export const formatTimeString = (time: {
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  const { hours, minutes, seconds } = time;
  const parts: string[] = [];

  if (hours > 0) {
    parts.push(`${hours}h`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }
  if (seconds > 0 || parts.length === 0) {
    parts.push(`${seconds}s`);
  }

  return parts.join(", ");
};
