import {
  ARBITRUM_MAINNET,
  ARBITRUM_SEPOLIA,
  BASE_MAINNET,
  BASE_SEPOLIA,
  ETHEREUM_MAINNET,
  ETHEREUM_SEPOLIA,
  NETWORKS,
  OPTIMISM_MAINNET,
  OPTIMISM_SEPOLIA,
  POLYGON_AMOY,
  POLYGON_MAINNET,
} from "@/constants/chains";

export const getNetworkRpc = (chainId: number) => {
  switch (chainId) {
    case ETHEREUM_MAINNET.chainId:
      return ETHEREUM_MAINNET.rpcUrl;
    case POLYGON_MAINNET.chainId:
      return POLYGON_MAINNET.rpcUrl;
    case ETHEREUM_SEPOLIA.chainId:
      return ETHEREUM_SEPOLIA.rpcUrl;
    case POLYGON_AMOY.chainId:
      return POLYGON_AMOY.rpcUrl;
    default:
      throw new Error("Network not supported");
  }
};

export const getNetworkNativeToken = (chainId: number) => {
  switch (chainId) {
    case POLYGON_MAINNET.chainId:
    case POLYGON_AMOY.chainId:
      return "MATIC";
    case ETHEREUM_MAINNET.chainId:
    case ETHEREUM_SEPOLIA.chainId:
      return "ETH";
    default:
      throw new Error("Network not supported");
  }
};

export const getFaucetUrl = (chainId: number) => {
  switch (chainId) {
    case POLYGON_AMOY.chainId:
      return "https://faucet.polygon.technology/";

    case ETHEREUM_SEPOLIA.chainId:
      return "https://sepoliafaucet.com/";
    default:
      return "https://moralis.io/best-free-crypto-faucet-sites-full-guide/"; // fallback
  }
};

export const getNetworkName = (chainId: number) => {
  switch (chainId) {
    case POLYGON_MAINNET.chainId:
      return "Polygon (Mainnet)";
    case POLYGON_AMOY.chainId:
      return "Polygon (Amoy)";
    case ETHEREUM_SEPOLIA.chainId:
      return "Ethereum (Sepolia)";
    case ETHEREUM_MAINNET.chainId:
      return "Ethereum (Mainnet)";
    default:
      throw new Error("Network not supported");
  }
};

export const getBlockExplorer = (
  chainId: number,
  type: "transaction" | "address" | "block" | "token",
  identifier: string // address, block num or transaction hash,
) => {
  // Base URL by chainId
  let baseUrl = "";

  // Name of block explorer
  let explorerName = "";

  switch (chainId) {
    case POLYGON_MAINNET.chainId:
      baseUrl = `https://polygonscan.com`;
      explorerName = "Polygonscan";
      break;
    case POLYGON_AMOY.chainId:
      baseUrl = `https://www.oklink.com/amoy`;
      explorerName = "Oklink";
      break;

    case ETHEREUM_MAINNET.chainId:
      baseUrl = `https://etherscan.io`;
      explorerName = "Etherscan";
      break;
    case ETHEREUM_SEPOLIA.chainId:
      baseUrl = `https://sepolia.etherscan.io`;
      explorerName = "Etherscan (Sepolia)";
      break;
    case ARBITRUM_MAINNET.chainId:
      baseUrl = `https://arbiscan.io`;
      explorerName = "Arbiscan";
      break;
    case ARBITRUM_SEPOLIA.chainId:
      baseUrl = `https://sepolia.arbiscan.io`;
      explorerName = "Arbiscan (Sepolia)";
      break;
    case BASE_MAINNET.chainId:
      baseUrl = `https://basescan.org`;
      explorerName = "Base Explorer";
      break;
    case BASE_SEPOLIA.chainId:
      baseUrl = `https://sepolia.basescan.org/`;
      explorerName = "Base Explorer (Sepolia)";
      break;
    case OPTIMISM_MAINNET.chainId:
      baseUrl = `https://optimistic.etherscan.io`;
      explorerName = "OP Explorer";
      break;
    case OPTIMISM_SEPOLIA.chainId:
      baseUrl = `https://sepolia.optimistic.etherscan.io`;
      explorerName = "OP Explorer (Sepolia)";
      break;
    default:
      throw new Error("Network not supported");
  }

  // Append path based on type (address, tx, block)
  switch (type) {
    case "transaction":
      return {
        url: `${baseUrl}/tx/${identifier}`,
        explorerName,
      };
    case "address":
      return { url: `${baseUrl}/address/${identifier}`, explorerName };
    case "block":
      return { url: `${baseUrl}/block/${identifier}`, explorerName };
    case "token":
      return { url: `${baseUrl}/token/${identifier}`, explorerName };
    default:
      throw new Error("Type not supported");
  }
};

export const isEVMAddress = (address: string) => {
  return (
    address &&
    address.length === 42 &&
    address.startsWith("0x") &&
    /^[0-9a-fA-F]{40}$/.test(address.substring(2))
  );
};

export const getNetworkNameFromChainId = (chainId: number) => {
  switch (chainId) {
    case ETHEREUM_MAINNET.chainId:
      return ETHEREUM_MAINNET.name;
    case ETHEREUM_SEPOLIA.chainId:
      return "Ethereum (Sepolia)";
    case POLYGON_MAINNET.chainId:
      return POLYGON_MAINNET.name;
    case POLYGON_AMOY.chainId:
      return "Polygon (Amoy)";
    case ARBITRUM_MAINNET.chainId:
      return ARBITRUM_MAINNET.name;
    case ARBITRUM_SEPOLIA.chainId:
      return "Arbitrum (Sepolia)";
    case BASE_MAINNET.chainId:
      return BASE_MAINNET.name;
    case BASE_SEPOLIA.chainId:
      return BASE_SEPOLIA.name;
    case OPTIMISM_MAINNET.chainId:
      return OPTIMISM_MAINNET.name;
    case OPTIMISM_SEPOLIA.chainId:
      return OPTIMISM_SEPOLIA.name;
  }
};
// Find the chainId based on common network name.
export const findChainIdByNetworkName = (networkName: string) => {
  if (!networkName) return 1; // default to Ethereum Mainnet
  if (networkName.toLowerCase() === "matic") {
    return POLYGON_MAINNET.chainId;
  }
  const networkConfig = NETWORKS.find((network) =>
    network.name.includes(networkName)
  );
  return networkConfig ? networkConfig.chainId : 1; // default to Ethereum Mainnet
};

export const getChainIdByNetwork = (network: string) => {
  switch (network) {
    case "ethereum":
      return ETHEREUM_MAINNET.chainId;
    case "polygon":
      return POLYGON_MAINNET.chainId;
    case "matic":
      return POLYGON_MAINNET.chainId;
    case "optimism":
      return OPTIMISM_MAINNET.chainId;
    case "arbitrum":
      return ARBITRUM_MAINNET.chainId;
    case "base":
      return BASE_MAINNET.chainId;
    default:
      return ETHEREUM_MAINNET.chainId;
  }
};
